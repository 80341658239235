<template>
	<h1 class="h1">Register</h1>
	<p class="lead">Maak een account met uw email</p>
	<div class="alert p-1 font-md bg-primary text-light" v-if="error">{{ error }}</div>
	<article
		class="container mt-0 col-12-xs col-12-sm col-12-mm col-12-md col-12-lg col-12-xl col-12-xx "
	>
		<div class="alert " v-if="isSignUp">{{ msg }}</div>

		<form class="p-3" @submit.prevent="stuur">
			<label for="email">Email: </label>
			<input
				type="email"
				placeholder="email"
				name="email"
				id="email"
				v-model="email"
				required
			/>
			<label for="password">Password: </label>
			<input
				type="password"
				placeholder="password"
				name="password"
				id="password"
				v-model="password"
				required
			/>
			<div class="row ">
				<p class="lead m-0 mt-2 mb-0 p-0 display-f">Ik ben Admin</p>
				<label for="admin" class="mt-0 switch">
					<input
						type="checkbox"
						name="admin"
						id="admin"
						v-model="admin"
					/>
					<span class="checkbox round "></span>
				</label>
			</div>
			<!-- 	<label for="klant" class="mt-2">Ik ben Klant: </label>
			<input type="radio" name="klant" id="klant" v-model="klant" /> -->

			<button class="btn mt-2" type="submit">
				Register
			</button>
		</form>
	</article>
</template>

<script>
	import { saveUser } from '@/data/db'

	export default {
		data() { 
			return {
				naam: '',
				email: '',
				password: '',
				error: '',
				admin: null,
				klant: null,
				time: new Date(),
				isSignUp: false,
				msg: null,
			}
		},

		methods: {
			async stuur() {
				try {
					await saveUser.createUserWithEmailAndPassword(
						this.email,
						this.password
					)
					this.$router.replace({ name: 'Login' })
				} catch (error) {
					this.error = error.message
					console.log(error)
				}
			},
		},
	}
</script>

<style scoped lang="scss">
	/* 	.container {
		input:checked ~ .checkbox {
			background-color: #eeff55;
			transform: rotate(45deg);
		}

		input:checked ~ .checkbox::after {
			display: block;
		}

		.checkbox::after {
			width: 5px;
			height: 10px;
			position: absolute;
			content: '';
		}
	}
 */
	.switch {
		position: relative;
		display: block;
		width: 60px;
		height: 35px;

		input {
			opacity: 0;
			width: 0;
			height: 0;
		}

		.checkbox {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: $light;
			border-radius: 25px;
			border: 1px solid $primary;
		}

		.checkbox::before {
			position: absolute;
			content: '';
			height: 24px;
			width: 24px;
			left: 5px;
			bottom: 5px;
			background-color: $dark;
			transition: all ease-in-out 0.6s;
			border-radius: 50%;
			border: none;
		}

		input:checked + .checkbox {
			background-color: $primary;
		}

		input:checked + .checkbox::before {
			transform: translateX(26px);
			background-color: $light;
		}
	}
</style>
